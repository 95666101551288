import React from 'react';

// Components
import AuthWrapper from 'Webapp/shared/app/components/auth/wrapper';
import LoginForm from 'Webapp/shared/app/containers/auth/login-form';

const Login = () => (
  <AuthWrapper>
    <LoginForm redirectAfterAuth />
  </AuthWrapper>
);

export default Login;
