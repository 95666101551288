import { Connector } from 'Utils/connector';

const selectors = {
  jobid: ({ app: { jobid } }: Flipboard.State) => {
    if (jobid) {
      return jobid.toString();
    }
    return 'UNSET-JOBID';
  },
};

export type ConnectJobidProps = Flipboard.ConnectorProps<typeof selectors>;

const connectJobid: Connector = {
  selectors,
};

export default connectJobid;
