import React from 'react';
import styled from '@emotion/styled';
import stringHash from 'string-hash-64';

import connector from 'Utils/connector';
import connectJobid, {
  ConnectJobidProps,
} from 'Webapp/shared/app/connectors/connectJobid';
import connectResponsive, {
  ConnectResponsiveProps,
} from 'Webapp/shared/app/connectors/connectResponsive';

const GRADIENT_STYLE = 'linear-gradient(rgba(0,0,0,0.35),rgba(0,0,0,0.35))';
const SPLASH_IMAGES = [
  'https://cdn.flipboard.com/web/soccer.jpg',
  'https://cdn.flipboard.com/web/diy.jpg',
  'https://cdn.flipboard.com/web/happiness.jpg',
  'https://cdn.flipboard.com/web/food.jpg',
  'https://cdn.flipboard.com/web/adventure_travel.jpg',
  'https://cdn.flipboard.com/web/architecture.jpg',
  'https://cdn.flipboard.com/web/Basketball.jpg',
  'https://cdn.flipboard.com/web/photography.jpg',
  'https://cdn.flipboard.com/web/fashion_trends.jpg',
  'https://cdn.flipboard.com/web/hiphop.jpg',
  'https://cdn.flipboard.com/web/aviation.jpg',
  'https://cdn.flipboard.com/web/crossfit.jpg',
  'https://cdn.flipboard.com/web/space.jpg',
  'https://cdn.flipboard.com/web/robotics.jpg',
  'https://cdn.flipboard.com/web/design.jpg',
  'https://cdn.flipboard.com/web/space_travel.jpg',
  'https://cdn.flipboard.com/web/running.jpg',
  'https://cdn.flipboard.com/web/travel.jpg',
];

const StyledSplash = styled.div({
  width: '100%',
  height: '100%',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
});

const getImage = (jobid) => {
  const seededIndex = stringHash(jobid) % SPLASH_IMAGES.length;
  return SPLASH_IMAGES[seededIndex] || SPLASH_IMAGES[seededIndex][0];
};

type SplashProps = ConnectResponsiveProps & ConnectJobidProps;

const Splash: React.FC<SplashProps> = ({ isPhone, jobid, children }) => {
  let style = {};
  if (!isPhone) {
    style = { backgroundImage: `${GRADIENT_STYLE}, url(${getImage(jobid)})` };
  }
  return <StyledSplash style={style}>{children}</StyledSplash>;
};

export default connector<SplashProps>(connectJobid, connectResponsive)(Splash);
