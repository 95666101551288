import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import Splash from 'Webapp/shared/app/components/auth/splash';

const StyledApp = styled.div({
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const AuthWrapper = ({ children }) => (
  <StyledApp id="content">
    <Splash>{children}</Splash>
  </StyledApp>
);

AuthWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthWrapper;
